import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import {Layout} from '../components/layout/layout'
import Seo from '../components/seo/seo'
import Hero from '../components/heroBanner/heroBanner'
import { SsyLink } from '../components/ssyLink/ssyLink'
import { SsyIntersectionObserver } from '../components/ssyIntersectionObserver/ssyIntersectionObserver'
import { RaisingBox } from '../components/raisingBox/raisingBox'
import style from "../assets/css/about.module.css";

const AboutUsPage = (props) => {
  const intl = useIntl()

  return (
    <Layout>
      <Seo
        title={intl.formatMessage({ id: 'ABOUTUS_META_TITLE' })}
        lang={intl.formatMessage({ id: 'MAIN_LANGUAGE' })}
        url={props.location.pathname}
        description={intl.formatMessage({ id: 'ABOUTUS_META_DESCRIPTION' })}
      />
      <Hero
        title={intl.formatMessage({ id: 'ABOUTUS_TITLE' })}
      />
      <SsyIntersectionObserver>
        <RaisingBox>
          <section className={style.container}>
            <p>
              {intl.formatMessage({ id: 'ABOUTUS_TEXT1' })}
            </p>
            <p>
              {intl.formatMessage({ id: 'ABOUTUS_TEXT2' })}
            </p>
            <p>
              {intl.formatMessage({ id: 'ABOUTUS_TEXT3' })}
            </p>
            <p>
              {intl.formatMessage({ id: 'ABOUTUS_TEXT4' })}
            </p>
            <SsyLink
              type="primary"
              to={`${intl.formatMessage({
                id: 'FOOTER_SUPPORT_CONTACT_LINK',
              })}`}
              internal={false}
              label={intl.formatMessage({ id: 'ABOUTUS_BUTTON' })}
            />
          </section>
        </RaisingBox>
      </SsyIntersectionObserver>
    </Layout>
  )
}

export default AboutUsPage
